@tailwind base;
@tailwind components;
@tailwind utilities;

.css-1rhbuit-multiValue {
    background-color: #243040 !important;
    color: white;
}

.css-12jo7m5 {
    color: white !important;
}

.css-14el2xx-placeholder{
    font-size: 13px;
}

@layer base {
  ul, ol {
    list-style: revert;
  }
}


.navbar a {
  float: left;
  text-align: center;
  text-decoration: none;
}

.dropdown{
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  text-decoration: none;
  display: block;
  text-align: left;
}



.dropdown:hover .dropdown-content {
  display: block;
}


